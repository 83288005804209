import {
  CreateOrganizationDto,
  JoinOrganizationErrorEmailDoesNotMatchDto,
  JoinOrganizationErrorEmailDomainNotAllowedDto,
  JoinOrganizationErrorSecretDoesNotMatchDto,
  JoinOrganizationErrorSecretExpiredDto,
  ResendOrganizationInvitationDto
} from '@api/Api';
import { apiClient } from '@api/client';

export const checkOrganizationSubdomain = (subdomain: string) =>
  apiClient.organization.organizationControllerCheckSubdomain({ subdomain });

export const suggestedOrganizationSubdomain = (organizationName: string) =>
  apiClient.organization.organizationControllerSuggestedSubdomain({
    organizationName
  });

export const createOrganization = (data: CreateOrganizationDto) =>
  apiClient.organization.organizationControllerCreateOrganization(data);

export const joinToOrganization = (invitationSecret: string) => {
  return apiClient.organization.organizationControllerJoinOrganization({
    invitationSecret
  });
};

export const resendOrganizationInvitation = (
  params: ResendOrganizationInvitationDto
) =>
  apiClient.organization.organizationControllerResendOrganizationInvitation(
    params
  );

export type JoinOrganizationError =
  | JoinOrganizationErrorSecretDoesNotMatchDto
  | JoinOrganizationErrorSecretExpiredDto
  | JoinOrganizationErrorEmailDoesNotMatchDto
  | JoinOrganizationErrorEmailDomainNotAllowedDto;
