import { Dispatch } from 'redux';
import {
  createWorkspace,
  getWorkspaceList,
  deleteWorkspace,
  changeWorkspaceName,
  leaveWorkspace
} from '@api/Workspace';

import {
  WorkspaceActionTypes,
  IWorkspaceAction
} from '@redux/types/workspaceType';
import {
  WorkspaceItemDto,
  CreateWorkspaceDto,
  RenameWorkspaceDto
} from '@api/Api';

export const updateWorkspace = (workspace: WorkspaceItemDto) => ({
  type: WorkspaceActionTypes.UPDATE_WORKSPACE,
  payload: { workspace }
});

export const setWorkspaceList = (
  payload: WorkspaceItemDto[]
): IWorkspaceAction => ({
  type: WorkspaceActionTypes.SET_WORKSPACE_LIST,
  payload
});

export const setWorkspaces =
  () => async (dispatch: Dispatch<IWorkspaceAction>) => {
    const workspace = await getWorkspaceList();
    dispatch(setWorkspaceList(workspace.data.list));
  };

export const createNewWorkspace =
  (data: CreateWorkspaceDto) =>
  async (dispatch: Dispatch<IWorkspaceAction>): Promise<WorkspaceItemDto> => {
    const {
      data: { workspace }
    } = await createWorkspace(data);

    dispatch({
      type: WorkspaceActionTypes.CREATE_NEW_WORKSPACE,
      payload: { workspace }
    });
    return workspace;
  };

export const removeWorkspace =
  ({
    workspaceId,
    organizationId
  }: {
    workspaceId: string;
    organizationId: string;
  }) =>
  async (dispatch: Dispatch<IWorkspaceAction>) => {
    await deleteWorkspace({ workspaceId });
    dispatch({
      type: WorkspaceActionTypes.REMOVE_WORKSPACE,
      payload: { workspaceId, organizationId }
    });
  };

export const renameWorkspace =
  (data: RenameWorkspaceDto & { organizationId: string }) =>
  async (dispatch: Dispatch<IWorkspaceAction>) => {
    await changeWorkspaceName(data);
    dispatch({ type: WorkspaceActionTypes.RENAME_WORKSPACE, payload: data });
  };

export const leaveWorkspaceAction =
  (workspaceId: string) => async (dispatch: Dispatch<IWorkspaceAction>) => {
    await leaveWorkspace({ workspaceId });
    dispatch({
      type: WorkspaceActionTypes.LEAVE_WORKSPACE,
      payload: workspaceId
    });
  };
