import React from 'react';
import { ReactComponent as LogoSvg } from '@assets/icons/logo.svg';
import { ReactComponent as LockSvg } from '@assets/icons/lock.svg';
import '@components/ExpiredLink/ExpiredLink.less';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { useAuth } from '@hooks/useAuth';

type InvitationEmailMismatchProps = {
  invitationEmail: string;
  actualEmail: string;
};

export default function InvitationEmailMismatch(
  props: InvitationEmailMismatchProps
) {
  const navigate = useNavigate();
  const { login } = useAuth();
  return (
    <div className="expired_link_container">
      <a
        className="expired_link_container__logotype"
        href="/"
        onClick={(e) => {
          e.preventDefault();
          navigate('/');
        }}
      >
        <LogoSvg className="logo" />
      </a>
      <div className="expired_link_container__body">
        <LockSvg />
        <div className="title">Your email does not match the invite email</div>
        <div className="description">
          The invite was sent to {props.invitationEmail} but you are currently
          logged in as {props.actualEmail}. Please log in with the correct email
          to accept the invitation.
        </div>
        <Button
          type="primary"
          onClick={() =>
            login({
              prompt: 'login',
              appState: {
                returnTo: window.location.pathname + window.location.search
              }
            })
          }
        >
          Sign in
        </Button>
      </div>
      <div className="expired_link_container__footer" style={{ height: 36 }} />
    </div>
  );
}
