import React, { ReactElement, useState, useEffect, useRef } from 'react';
import { FormInstance, Form, Input, Button } from 'antd';
import { emailValidatorRegExp } from '@helpers/validators';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { ReactComponent as PlusSvg } from '@assets/icons/plus.svg';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';

type InvitationStepProps = {
  form: FormInstance;
  loading: boolean;
  onInviteMembers: () => void;
};

function InvitationStep({
  form,
  loading,
  onInviteMembers
}: InvitationStepProps): ReactElement {
  const [emails, setEmails] = useState<string[]>(['', '', '']);
  const [isInviteBtnDisabled, setInviteDisableState] = useState<boolean>(true);
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const emailListScrollerRef = useRef<any>(null);

  useEffect(() => {
    const touchedEmails = emails.filter((email) => email && email.length > 1);
    setInviteDisableState(
      !(
        touchedEmails.length > 0 &&
        touchedEmails.every((email) => emailValidatorRegExp.test(email))
      )
    );
  }, [emails]);

  const handleSetErrors = () => {
    const fieldErrors = form.getFieldsError();
    const emailsErrors =
      fieldErrors.find((field) => field?.name.includes('emails'))?.errors ||
      null;
    setHasErrors(Boolean(emailsErrors && emailsErrors?.length > 0));
  };

  return (
    <div className="step_container" style={{ maxWidth: 420, margin: '0 auto' }}>
      <p className="step_container__title step_container__title--invitation">
        StreamWork is better together
      </p>
      <p className="step_container__description">
        Invite others to work with you
      </p>
      <div className="step_container__body">
        <Form.List name="emails" initialValue={[...emails]}>
          {(fields, { add, remove }) => (
            <>
              <OverlayScrollbarsComponent
                ref={emailListScrollerRef}
                className="step_container__emails"
                options={{
                  callbacks: {
                    onContentSizeChanged: () => {
                      setTimeout(() => {
                        const osInstance =
                          emailListScrollerRef?.current?.osInstance();
                        if (!osInstance) return;
                        const {
                          max: { y: scrollHeight }
                        } = osInstance.scroll();
                        osInstance.scroll([0, scrollHeight], 300);
                      }, 150);
                    }
                  }
                }}
              >
                {fields.map((field, idx) => (
                  <Form.Item
                    {...field}
                    key={field.key}
                    validateTrigger="onBlur"
                    hasFeedback={false}
                    rules={[
                      {
                        type: 'email',
                        message: ''
                      }
                    ]}
                  >
                    <Input
                      onChange={() => setEmails(form.getFieldsValue().emails)}
                      size="large"
                      onBlur={() => handleSetErrors()}
                      onFocus={() => handleSetErrors()}
                      placeholder="Enter email to invite others"
                      suffix={
                        idx > 2 ? (
                          <CrossSvg
                            onClick={() => remove(idx)}
                            style={{ cursor: 'pointer' }}
                          />
                        ) : undefined
                      }
                    />
                  </Form.Item>
                ))}
              </OverlayScrollbarsComponent>
              <Button
                type="link"
                style={{ float: 'left', padding: 0, height: 32, marginTop: 16 }}
                onClick={() => add()}
                onMouseDown={(e) => e.preventDefault()}
              >
                <PlusSvg style={{ marginRight: 4 }} /> Add more emails
              </Button>
              <Button
                loading={loading}
                onClick={onInviteMembers}
                type="primary"
                size="large"
                style={{ width: '100%', marginTop: 32 }}
                disabled={isInviteBtnDisabled}
              >
                Invite others
              </Button>
              {hasErrors && (
                <div className="ant-form-item-explain-error">
                  Whoops! Some emails are invalid. Please check them for typos
                  or mistakes.
                </div>
              )}
            </>
          )}
        </Form.List>
      </div>
    </div>
  );
}

export default InvitationStep;
