import { ChangeOrganizationMembersRoleMemberDto } from '@api/Api';
import { useOrganization } from '@components/OrganizationBoundary';
import { useMixpanel } from 'react-mixpanel-browser';
import useTypedDispatch from '@hooks/useTypedDispatch';
import {
  useWebSocketDebouncedSubscription,
  useWebSocketSubscription
} from '@hooks/useWebSocketClient';
import {
  cancelOrganizationSubscription,
  changeOrganizationMemberRole,
  deleteOrganization,
  deleteOrganizationMember,
  deleteOrganizationPaymentMethod,
  fetchOrganizationById,
  ICancelOrganizationSubscriptionPayload,
  IUpdateOrganizationSubscriptionPayload,
  leaveOrganization,
  renameOrganization,
  updateOrganizationSubscription
} from '@redux/actions/organizations';
import {
  deleteOrganizationFromList,
  setOrganizationInvoicesListNeedRefresh,
  setOrganizationName
} from '@redux/reducers/organizations';
import { useCallback } from 'react';
import { useAuth } from '@hooks/useAuth';

export type CancelOrganizationSubscriptionProps = Omit<
  ICancelOrganizationSubscriptionPayload,
  'mixpanel' | 'userId'
>;

export type UpdateOrganizationSubscriptionProps = Omit<
  IUpdateOrganizationSubscriptionPayload,
  'mixpanel' | 'userId'
>;

export function useDeleteOrganization() {
  const dispatch = useTypedDispatch();
  return useCallback(
    (params: { organizationId: string }) => {
      return dispatch(deleteOrganization(params));
    },
    [dispatch]
  );
}

export function useLeaveOrganization() {
  const dispatch = useTypedDispatch();
  return useCallback(
    (params: { organizationId: string }) => {
      return dispatch(leaveOrganization(params));
    },
    [dispatch]
  );
}

export function useRenameOrganization() {
  const dispatch = useTypedDispatch();
  return useCallback(
    (params: { organizationId: string; name: string }) => {
      return dispatch(renameOrganization(params));
    },
    [dispatch]
  );
}

export function useDeletePaymentMethod() {
  const dispatch = useTypedDispatch();
  return useCallback(
    (params: { organizationId: string }) => {
      return dispatch(deleteOrganizationPaymentMethod(params));
    },
    [dispatch]
  );
}

export function useUpdateOrganizationSubscription() {
  const dispatch = useTypedDispatch();
  const mixpanel = useMixpanel();
  const { user } = useAuth();
  const userId = user?.id;
  return useCallback(
    (params: UpdateOrganizationSubscriptionProps) => {
      if (!userId) throw new Error('Auth required');
      return dispatch(
        updateOrganizationSubscription({ ...params, mixpanel, userId })
      );
    },
    [dispatch, mixpanel, userId]
  );
}

export function useCancelOrganizationSubscription() {
  const dispatch = useTypedDispatch();
  const mixpanel = useMixpanel();
  const { user } = useAuth();
  const userId = user?.id;
  return useCallback(
    (params: CancelOrganizationSubscriptionProps) => {
      if (!userId) throw new Error('Auth required');
      return dispatch(
        cancelOrganizationSubscription({ ...params, mixpanel, userId })
      );
    },
    [dispatch, mixpanel, userId]
  );
}

export function useDeleteOrganizationMember() {
  const dispatch = useTypedDispatch();
  return useCallback(
    (params: { organizationId: string; memberId: string[] }) => {
      return dispatch(deleteOrganizationMember(params));
    },
    [dispatch]
  );
}

export function useChangeOrganizationMemberRole() {
  const dispatch = useTypedDispatch();
  return useCallback(
    (params: {
      organizationId: string;
      members: ChangeOrganizationMembersRoleMemberDto[];
    }) => {
      return dispatch(changeOrganizationMemberRole(params));
    },
    [dispatch]
  );
}

export function useOrganizationWebSocketSubscription() {
  const dispatch = useTypedDispatch();
  const { currentOrganization } = useOrganization(false);
  useWebSocketSubscription(
    async ({ causedByMe, payload: { organizationId } }) => {
      if (causedByMe) return;
      dispatch(deleteOrganizationFromList({ organizationId }));
    },
    'organization-removed'
  );

  useWebSocketDebouncedSubscription(
    (msg) => msg.payload.organizationId,
    async (messages) => {
      const lastMessage = messages[messages.length - 1];
      const { reason, organizationId } = lastMessage.payload;

      if (reason === 'role-changed') {
        if (organizationId !== currentOrganization?.entity?.id) return;
        dispatch(
          fetchOrganizationById({ organizationId, fetchType: 'refresh' })
        );
      }

      if (reason === 'lost-access') {
        dispatch(deleteOrganizationFromList({ organizationId }));
      }

      if (reason === 'have-access') {
        dispatch(
          fetchOrganizationById({ organizationId, fetchType: 'initial' })
        );
      }
    },
    1000,
    (msg) => {
      if (msg.causedByMe) return false;
      return true;
    },
    'organization-access-changed'
  );

  useWebSocketDebouncedSubscription(
    (msg) => msg.payload.organizationId,
    (messages) => {
      const lastMessage = messages[messages.length - 1];
      const { organizationId, newName } = lastMessage.payload;
      dispatch(setOrganizationName({ name: newName, organizationId }));
    },
    1000,
    (msg) => {
      if (msg.causedByMe) return false;
      return true;
    },
    'organization-updated'
  );
  useWebSocketDebouncedSubscription(
    (msg) => msg.payload.organizationId,
    async (messages) => {
      if (!currentOrganization?.entity) return;
      const lastMessage = messages[messages.length - 1];
      const { organizationId } = lastMessage.payload;
      if (organizationId !== currentOrganization?.entity.id) return;
      dispatch(setOrganizationInvoicesListNeedRefresh({ organizationId }));
    },
    1000,
    undefined,
    'invoices-changed'
  );
}
