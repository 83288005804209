import React, { memo, useCallback } from 'react';
import { Button, message, Modal } from 'antd';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { useTypedSelector } from '@hooks';
import { toggleCancelSubscriptionModal } from '@redux/actions/modalAction';
import { useOrganization } from '@components/OrganizationBoundary';
import { ReactComponent as SuccessSvg } from '@assets/icons/success.svg';
import { useCancelOrganizationSubscription } from '@hooks/organization';
import useTypedDispatch from '@hooks/useTypedDispatch';
import { fetchOrganizationById } from '@redux/actions/organizations';

export default memo(function CancelSubscriptionModal() {
  const dispatch = useTypedDispatch();
  const cancelOrganizationSubscription = useCancelOrganizationSubscription();
  const { currentOrganization } = useOrganization();
  const modalState = useTypedSelector(
    ({ modal }) => modal.cancelSubscriptionModal
  );

  const onCancelSubscription = useCallback(() => {
    if (!currentOrganization.entity) return;
    const organizationId = currentOrganization.entity.id;
    cancelOrganizationSubscription({ organizationId });
    dispatch(fetchOrganizationById({ organizationId, fetchType: 'refresh' }));
    message.success({
      key: 'change-plan-modal',
      content: `Welcome to the StreamWork Free plan!`,
      icon: <SuccessSvg className="anticon" />,
      className: 'message-dark-modal',
      duration: 3
    });
  }, [currentOrganization, dispatch, cancelOrganizationSubscription]);

  return (
    <Modal
      visible={modalState?.visible}
      destroyOnClose
      footer={null}
      centered={true}
      closeIcon={<CrossSvg />}
      width={600}
      onCancel={() => dispatch(toggleCancelSubscriptionModal(null))}
      zIndex={1006}
    >
      <div className="modal_container">
        <h2
          className="modal_container__title"
          style={{ fontSize: 22, marginBottom: 32, justifyContent: 'center' }}
        >
          Confirm your cancellation
        </h2>
        <div className="cancel_subscription_story">
          <p>
            When your billing period ends, you will be downgraded to a Free plan
            and{' '}
            <strong>will lose access to premium features and storage.</strong>
          </p>
          <p>
            Please ensure your storage usage is below the 2GB storage limit of
            the Free plan.{' '}
          </p>
          <p>
            If you need to pause your subscription and keep your creative media
            in StreamWork until you return, contact{' '}
            <strong>support@streamwork</strong>.
          </p>
          <p>Are you sure you want to cancel your subscription?</p>
        </div>

        <div className="modal_container__actions" style={{ marginTop: 32 }}>
          <Button
            type="text"
            size="large"
            style={{
              marginRight: 16,
              border: 'none',
              height: 48,
              padding: '8px 32px'
            }}
            onClick={() => dispatch(toggleCancelSubscriptionModal(null))}
          >
            Don’t cancel
          </Button>
          <Button
            type="primary"
            style={{
              width: 251,
              height: 48
            }}
            disabled={
              currentOrganization.entity?.planType === 'free' ||
              currentOrganization.entity?.planWillBeCanceled
            }
            onClick={() => {
              onCancelSubscription();
              dispatch(toggleCancelSubscriptionModal(null));
            }}
          >
            Cancel subscription
          </Button>
        </div>
      </div>
    </Modal>
  );
});
