import {
  CardCreatedDto,
  ReplaceCardDto,
  SubscriptionControllerCancelSubscriptionParams,
  SubscriptionControllerDeleteCardParams,
  SubscriptionControllerGetCardParams,
  SubscriptionControllerGetInvoiceDownloadUrlParams,
  SubscriptionControllerGetInvoicesListParams,
  SubscriptionControllerGetSubscriptionParams,
  SubscriptionCreatedDto,
  UpdateSubscriptionDto
} from '@api/Api';
import { apiClient } from '@api/client';

export const updateSubscription = (params: UpdateSubscriptionDto) =>
  apiClient.subscription.subscriptionControllerUpdateSubscription(params);

export const setSubscriptionCreated = (params: SubscriptionCreatedDto) =>
  apiClient.subscription.subscriptionControllerSetSubscriptionCreated(params);

export const subscriptionGetCard = (
  params: SubscriptionControllerGetCardParams
) => apiClient.subscription.subscriptionControllerGetCard(params);

export const subscriptionDeleteCard = (
  params: SubscriptionControllerDeleteCardParams
) => apiClient.subscription.subscriptionControllerDeleteCard(params);

export const subscriptionReplaceCard = (params: ReplaceCardDto) =>
  apiClient.subscription.subscriptionControllerReplaceCard(params);

export const subscriptionSetCardCreated = (params: CardCreatedDto) =>
  apiClient.subscription.subscriptionControllerSetCardCreated(params);

export const subscriptionGetSubscription = (
  params: SubscriptionControllerGetSubscriptionParams
) => apiClient.subscription.subscriptionControllerGetSubscription(params);

export const cancelSubscription = (
  params: SubscriptionControllerCancelSubscriptionParams
) => apiClient.subscription.subscriptionControllerCancelSubscription(params);

export const subscriptionGetInvoicesList = (
  params: SubscriptionControllerGetInvoicesListParams
) => apiClient.subscription.subscriptionControllerGetInvoicesList(params);

export const subscriptionGetInvoiceDownloadUrl = (
  params: SubscriptionControllerGetInvoiceDownloadUrlParams
) => apiClient.subscription.subscriptionControllerGetInvoiceDownloadUrl(params);
