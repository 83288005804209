import React from 'react';
import { ReactComponent as LogoSvg } from '@assets/icons/logo.svg';
import { ReactComponent as LockSvg } from '@assets/icons/lock.svg';
import '@components/ExpiredLink/ExpiredLink.less';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { useAuth } from '@hooks/useAuth';

type InvitationDomainMismatchProps = {
  email: string;
  allowedDomains: string[];
};

export default function InvitationDomainMismatch(
  props: InvitationDomainMismatchProps
) {
  const navigate = useNavigate();
  const { login } = useAuth();
  return (
    <div className="expired_link_container">
      <a
        className="expired_link_container__logotype"
        href="/"
        onClick={(e) => {
          e.preventDefault();
          navigate('/');
        }}
      >
        <LogoSvg className="logo" />
      </a>
      <div className="expired_link_container__body">
        <LockSvg />
        <div className="title">
          Your email domain does not match the allowed domain
          {props.allowedDomains.length > 1 ? 's' : ''}
        </div>
        <div className="description">
          You are currently logged in as {props.email}, but the allowed domain
          {props.allowedDomains.length > 1 ? 's are' : ' is'}{' '}
          {props.allowedDomains.join(', ')}. Please log in with an email from
          the correct domain to accept the invite.
        </div>
        <Button
          type="primary"
          onClick={() =>
            login({
              prompt: 'login',
              appState: {
                returnTo: window.location.pathname + window.location.search
              }
            })
          }
        >
          Sign in
        </Button>
      </div>
      <div className="expired_link_container__footer" style={{ height: 36 }} />
    </div>
  );
}
