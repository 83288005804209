import React, { useState } from 'react';
import { Button } from 'antd';
import { useMixpanel } from 'react-mixpanel-browser';
import { resendMailVerificationCode, verifyEmail } from '@api/Profile';
import { chooseEmailService } from '@helpers/auth';
import { ReactComponent as CircleArrowLeft } from '@assets/icons/arrow-left.svg';
import CodeLine from '@components/CodeLine';
import { useAuth } from '@hooks/useAuth';
import { MixpanelEventType, MixpanelService } from '@services/mixpanelService';
import LottieComponent from '@components/Lottie';

const CODELINE_LENGTH = 6;

function EmailConfirmation() {
  const { logout } = useAuth();
  const { user, updateUserProfile } = useAuth(true, 'internal');
  const mixpanel = useMixpanel();
  const [isEmailConfirmed, setIsEmailConfirmed] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [sendCodeDisabled, setSendCodeDisabled] = useState<boolean>(false);

  const handleResendCode = async () => {
    setSendCodeDisabled(true);
    await resendMailVerificationCode();
    setSendCodeDisabled(false);
  };

  const onEmailVerify = async (code: string) => {
    try {
      await verifyEmail(code);
      setIsEmailConfirmed(true);
      setTimeout(() => {
        updateUserProfile({ emailVerified: true });
      }, 4000);
      await MixpanelService.track(
        mixpanel,
        user.id,
        MixpanelEventType.CONFIRM_EMAIL
      );
    } catch (err) {
      setError(true);
    }
  };

  const handleChange = async (code: string) => {
    setError(false);
    if (code.length === CODELINE_LENGTH) {
      setDisabled(true);
      await onEmailVerify(code);
      setDisabled(false);
    }
  };

  const { service, Logo, link } = chooseEmailService(user.email || '');

  return (
    <div className="onboarding_container">
      <div className="onboarding_container__secure">
        <div
          className="step_container"
          style={{ maxWidth: 420, margin: '0 auto' }}
        >
          <p className="step_container__title">
            {!isEmailConfirmed ? 'Confirm your email' : 'Email confirmed'}
          </p>
          <p className="step_container__description">
            {!isEmailConfirmed
              ? 'Enter the code we just emailed you.'
              : 'We’re thrilled to have you on board!'}
          </p>
          <div className="step_container__body" style={{ textAlign: 'center' }}>
            {!isEmailConfirmed ? (
              <div>
                {Logo && service && link && (
                  <Button
                    type="text"
                    size="large"
                    className="provider"
                    href={link}
                    target="_blank"
                  >
                    <Logo />
                    Go To {service}
                  </Button>
                )}

                <CodeLine
                  length={CODELINE_LENGTH}
                  onChange={(code) => handleChange(code)}
                  isValid={!error}
                  disabled={disabled}
                />

                <p className="resend">
                  <Button
                    type="link"
                    disabled={disabled || sendCodeDisabled}
                    onClick={handleResendCode}
                  >
                    Send code
                  </Button>
                  <span>again or chat with</span>
                  <Button type="link">our support team</Button>
                </p>
                <p className="info-copy">
                  If you don&apos;t see an email in your inbox, check your spam
                  or promotions folder
                </p>
              </div>
            ) : (
              <div className="success">
                <LottieComponent view="success" />
              </div>
            )}
          </div>
        </div>
        <div className="onboarding_container__controller">
          <Button
            className="onboarding_container__controller_button onboarding_container__controller_button--prev"
            type="text"
            onClick={() => logout({ returnTo: window.location.origin })}
          >
            <div className="icon">
              <CircleArrowLeft />
            </div>
            Back
          </Button>
        </div>
      </div>
    </div>
  );
}

export default EmailConfirmation;
