import React from 'react';
import './PlanCard.less';
import { Button } from 'antd';
import classNames from 'classnames';
import { ReactComponent as CheckMarkSvg } from '@assets/icons/check-mark-blue.svg';
import { ReactComponent as AdvancedStarsSvg } from '@assets/icons/billing-advanced-stars-icon.svg';
import { ReactComponent as EnterpriseSvg } from '@assets/icons/billing-enterprise-icon.svg';
import { useDispatch } from 'react-redux';
import {
  toggleBillingModal,
  toggleCancelSubscriptionModal,
  toggleChangePlanDetailsModal
} from '@redux/actions/modalAction';

interface PlanCardProps {
  name: string;
  description: string;
  price?: string | number | null;
  buttonText: string;
  isButtonDisabled: boolean;
  offerTitle: string;
  offerServicesList: string[];
  value: 'free' | 'starter' | 'team' | 'enterprise';
  paymentMethod: 'monthly' | 'annually';
}

function PlanCard({
  name,
  description,
  price,
  buttonText,
  isButtonDisabled,
  offerTitle,
  offerServicesList,
  value,
  paymentMethod
}: PlanCardProps) {
  const dispatch = useDispatch();
  return (
    <div
      className={classNames(
        'plan-card-container',
        value === 'team' && 'advanced-plan-card-container'
      )}
    >
      {value === 'team' && (
        <div className="advanced-container">
          <AdvancedStarsSvg />
          <span>Our most popular plan</span>
        </div>
      )}
      <span className="plan-name">{name}</span>
      <span className="plan-description">{description}</span>
      {price !== undefined && price !== null && (
        <div className="price-container">
          <span>${price}</span>
          <span>/ per user</span>
        </div>
      )}
      {value === 'enterprise' && (
        <div className="enterprise-icon-container">
          <EnterpriseSvg />
        </div>
      )}

      <Button
        className="button"
        disabled={isButtonDisabled}
        onClick={async () => {
          if (value === 'free') {
            dispatch(toggleCancelSubscriptionModal({ visible: true }));
          } else if (value === 'enterprise') {
            window.open('https://www.streamwork.com/contact', '_blank');
          } else {
            dispatch(
              toggleChangePlanDetailsModal({
                visible: true,
                paymentMethod,
                value
              })
            );
          }
          dispatch(toggleBillingModal(null));
        }}
      >
        {buttonText}
      </Button>
      <div className="offer-container">
        <span>{offerTitle}</span>
        {offerServicesList.map((item, idx) => {
          if (!item) return;
          return (
            <div className="offer-services-item" key={idx}>
              <CheckMarkSvg />
              <span>{item}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PlanCard;
