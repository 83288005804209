import React from 'react';
import { ReactComponent as CheckMarkSvg } from '@assets/icons/check-mark-blue.svg';
import { ReactComponent as LineSvg } from '@assets/icons/line-no-has-feature.svg';

const planFeatureContent = [
  {
    titleText: 'Usage',
    tableContent: [
      {
        features: 'Number of users',
        free: '2 users',
        starter: 'Up to 5 users',
        team: '5 - 25 users',
        enterprise: '10 users minimum'
      },
      {
        features: 'Assets',
        free: 'Unlimited',
        starter: 'Unlimited',
        team: 'Unlimited',
        enterprise: 'Unlimited'
      },
      {
        features: 'Secure storage',
        free: '2GB storage',
        starter: '200GB storage',
        team: '500GB storage',
        enterprise: 'Custom'
      },
      {
        features: 'Storage add-ons',
        free: <LineSvg />,
        starter: '250GB increments',
        team: '250GB increments',
        enterprise: 'Custom'
      },
      {
        features: 'Free Reviewers',
        free: 'Unlimited',
        starter: 'Unlimited',
        team: 'Unlimited',
        enterprise: 'Unlimited'
      }
    ]
  },
  {
    titleText: 'Workspaces & Campaigns',
    tableContent: [
      {
        features: 'Number of workspaces',
        free: '1 workspace',
        starter: '5 workspaces',
        team: '10 workspaces',
        enterprise: 'Unlimited'
      },
      {
        features: 'Number of campaigns',
        free: '1 campaign',
        starter: '5 campaigns',
        team: '15 campaigns',
        enterprise: 'Unlimited'
      },
      {
        features: 'Media Library',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Private campaigns',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Private folders',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      }
    ]
  },
  {
    titleText: 'Creative Feedback & Collaboration',
    tableContent: [
      {
        features:
          'Real-time comment threads on imagery, photography, presentations, PDFs and more',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Time-stamped comments on videos',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Range-based comments on videos',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Markup an asset (arrows, shapes, freeform, text, pins)',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Drop pins on images, PDFs, presentations',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Filter and sort comments',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Layered version management',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Version stack different file formats',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Download original file',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Download video proxies',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Thumbnail page viewer for PDFs and presentations',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Download comments (CSV, XML, plain text)',
        free: <LineSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: '@mentions in creative feedback',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Priority stakeholder comments',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <LineSvg />,
        enterprise: <CheckMarkSvg />
      }
    ]
  },
  {
    titleText: 'Share for Review',
    tableContent: [
      {
        features: 'Single asset share links',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Disable comments on share links',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Disable downloads on share links',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Share one or multiple versions on share link',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'View, manage and delete active share links',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Multi-asset share links',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Password-protected share links',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      }
    ]
  },
  {
    titleText: 'Project Management',
    tableContent: [
      {
        features: 'Turn creative feedback into tasks',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Custom task grouping',
        free: '1 custom task group per campaign',
        starter: '5 custom task groups per campaign',
        team: '10 custom task groups per campaign',
        enterprise: 'Unlimited'
      },
      {
        features: 'Task assignees',
        free: '1 assignee per task',
        starter: '1 assignee per task',
        team: 'Multiple assignees per task',
        enterprise: 'Multiple assignees per task'
      },
      {
        features: 'Set task status',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Set task due date',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Set task priority',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Real-time commenting in tasks',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Task activity log',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'View tasks per campaign',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Filter & sort tasks',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Universal task view',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Project Management integrations',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <LineSvg />,
        enterprise: <CheckMarkSvg />
      }
    ]
  },
  {
    titleText: 'Approval Workflows',
    tableContent: [
      {
        features: 'Approval routing builder',
        free: <LineSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Approval stages',
        free: <LineSvg />,
        starter: '1 stage per approval routing',
        team: '3 stages per approval routing',
        enterprise: 'Custom'
      },
      {
        features: 'Reviewers',
        free: <LineSvg />,
        starter: 'Up to 5 reviewers per stage',
        team: 'Up to 10 reviewers per stage',
        enterprise: 'Unlimited'
      },
      {
        features: 'Approval status dashboard per campaign',
        free: <LineSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Specify when stage is completed',
        free: <LineSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Set primary decision maker',
        free: <LineSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Add custom message to approval invites',
        free: <LineSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Option to skip reviewer decisions',
        free: <LineSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Set upload permissions',
        free: <LineSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Automatic approval deadline reminders',
        free: <LineSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Approval routing due dates',
        free: <LineSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Pause approval workflow',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Edit in-progress approval workflow',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Restart approval workflow ',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Add password to approval routing invites',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Add same reviewer to multiple stages',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      }
    ]
  },
  {
    titleText: 'Integrations',
    tableContent: [
      {
        features: 'Slack Integration',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Project Management Integrations',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <LineSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'DAM/ Cloud Storage Integrations',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <LineSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Education Gradebook',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <LineSvg />,
        enterprise: <CheckMarkSvg />
      }
    ]
  },
  {
    titleText: 'Organization Management',
    tableContent: [
      {
        features: 'Custom organization domain',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Monitor storage usage',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Workspace invite links',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Campaign invite links',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'White-label organization domain',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <LineSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Multiple admins',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <LineSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Enterprise access management',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <LineSvg />,
        enterprise: <CheckMarkSvg />
      }
    ]
  },
  {
    titleText: 'Security & Privacy',
    tableContent: [
      {
        features: 'SOC 2 Type II certified',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'AES-256 bit encryption',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Google SSO',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Two-factor authentication',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Single Sign-On',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <LineSvg />,
        enterprise: <CheckMarkSvg />
      }
    ]
  },
  {
    titleText: 'Customer Success',
    tableContent: [
      {
        features: 'Knowledge Base',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'In-product onboarding',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Live chat support',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Email support',
        free: <CheckMarkSvg />,
        starter: <CheckMarkSvg />,
        team: <CheckMarkSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Onboarding',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: '1 hour onboarding',
        enterprise: 'Custom'
      },
      {
        features: 'Custom implementation',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <LineSvg />,
        enterprise: <CheckMarkSvg />
      },
      {
        features: 'Priority support',
        free: <LineSvg />,
        starter: <LineSvg />,
        team: <LineSvg />,
        enterprise: <CheckMarkSvg />
      }
    ]
  }
];

export default planFeatureContent;
