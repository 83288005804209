import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from '@hooks';
import { Modal, Form, Button } from 'antd';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { toggleWorkspaceOrganizationLeaveModal } from '@redux/actions/modalAction';
import { leaveWorkspaceAction } from '@redux/actions/workspaceAction';
import { redirectToSubdomain } from '@components/OrganizationBoundary';
import { useLeaveOrganization } from '@hooks/organization';

interface IModalCopys {
  [id: string]: { title: string; description: string };
}

const modalCopys: IModalCopys = {
  organization: {
    title: 'Leave organization',
    description: 'Are you sure you want to leave this organization?'
  },
  workspace: {
    title: 'Leave workspace',
    description: 'Are you sure you want to leave this workspace?'
  }
};

function LeaveEntityModel() {
  const dispatch = useDispatch();
  const leaveOrganization = useLeaveOrganization();
  const [copys, setCopys] = useState<{
    title: string;
    description: string;
  } | null>(null);

  const entityLeaveModal = useTypedSelector(
    ({ modal }) => modal.workspaceOrganizationLeaveModal
  );
  useEffect(() => {
    if (entityLeaveModal?.visible) {
      const temp = modalCopys[entityLeaveModal.entityName];
      setCopys(temp);
    }
  }, [entityLeaveModal]);
  const onConfirm = async () => {
    if (entityLeaveModal?.entityName === 'workspace')
      dispatch(leaveWorkspaceAction(entityLeaveModal?.entity?.id || ''));

    if (entityLeaveModal?.entityName === 'organization') {
      if (entityLeaveModal.entity) {
        leaveOrganization({ organizationId: entityLeaveModal.entity.id });
        if (entityLeaveModal.default)
          redirectToSubdomain(entityLeaveModal.default);
      }
    }

    dispatch(toggleWorkspaceOrganizationLeaveModal(null));
  };
  return (
    <Modal
      visible={entityLeaveModal?.visible}
      footer={null}
      destroyOnClose
      centered={true}
      closeIcon={<CrossSvg />}
      width={500}
      onCancel={() => dispatch(toggleWorkspaceOrganizationLeaveModal(null))}
    >
      <div className="modal_container">
        <h2
          className="modal_container__title"
          style={{ fontSize: 22, marginBottom: '0.5em' }}
        >
          {copys?.title}
        </h2>
        <p className="modal_container__subtitle">{copys?.description}</p>
        <Form layout="vertical" onFinish={onConfirm}>
          <div className="modal_container__actions">
            <Button
              onClick={() =>
                dispatch(toggleWorkspaceOrganizationLeaveModal(null))
              }
              type="ghost"
              size="large"
              style={{
                marginRight: '20px',
                border: 'none',
                height: 48,
                padding: '8px 15px'
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              style={{ width: 216, height: 48, padding: '8px 15px' }}
            >
              {copys?.title}
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
}

export default LeaveEntityModel;
