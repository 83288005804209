import React, { useState, useEffect, useRef, ReactElement } from 'react';
import {
  suggestedOrganizationSubdomain,
  checkOrganizationSubdomain
} from '@api/Organization';
import { subdomainPopoverList } from '@helpers/onboarding';
import { Form, Input, Popover, FormInstance } from 'antd';
import { slugify } from '@helpers/stringTransformers';

type OrganizationCreationStepProps = {
  form: FormInstance;
  setIsDisabled: (a: boolean) => void;
};

function OrganizationCreationStep({
  form,
  setIsDisabled
}: OrganizationCreationStepProps): ReactElement {
  const [proposedSubdomain, setProposedSubdomain] = useState<string>('');
  const [subdomain, setSubdomain] = useState<string | null | undefined>('');

  const [width, setWidth] = useState<number>(0);
  const [windowWidth, setWindowWidth] = useState<number>(375);
  const span = useRef<any>();

  const subdomainPopoverContent = subdomainPopoverList.map((el) => (
    <li key={el}>{el}</li>
  ));

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return function cleanup() {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    const input = document.getElementById('INPUT');
    let spanWidth = width;
    if (span.current) {
      spanWidth = span.current.getBoundingClientRect().width;
    }
    if (input) input.style.width = `${spanWidth || 0}px`;
    setWidth(spanWidth);
  }, [subdomain, width]);

  useEffect(() => {
    setIsDisabled(true);
    const delayDebounceFn = setTimeout(async () => {
      try {
        if (!proposedSubdomain) return;
        const {
          data: { subdomain }
        } = await suggestedOrganizationSubdomain(proposedSubdomain);
        form.setFields([
          {
            name: 'subdomain',
            value: subdomain,
            errors: []
          }
        ]);
        setSubdomain(subdomain);

        const hasErrors = form
          .getFieldsError()
          .some(({ errors }: any) => errors && errors.length > 0);

        setIsDisabled(hasErrors);
      } catch (e) {
        setIsDisabled(true);
        form.setFields([
          {
            name: 'subdomain',
            value: ''
          }
        ]);
        setSubdomain('');
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [setIsDisabled, form, proposedSubdomain]);

  const onChangeSearchTerm = (str: string) => {
    const newStr = str.trimStart();
    form.setFields([{ name: 'name', value: newStr }]);
    setProposedSubdomain(slugify(newStr));
  };

  const onChangeSubdomain = (value: string) => {
    setIsDisabled(true);

    const subdomain = value.replace(/ /g, '').toLocaleLowerCase().trim();
    setSubdomain(subdomain);

    const delayDebounceFn = setTimeout(async () => {
      try {
        if (!subdomain) {
          setIsDisabled(true);
          form.setFields([
            {
              name: 'subdomain',
              errors: ['Please enter domain name.']
            }
          ]);
          return;
        }
        const { data } = await checkOrganizationSubdomain(subdomain);
        if (!data.available) {
          form.setFields([
            {
              name: 'subdomain',
              errors: [
                `Whoops! That subdomain isn't available, but ${data.suggested} is available.`
              ]
            }
          ]);
          setIsDisabled(true);
        } else {
          form.setFields([
            {
              name: 'subdomain',
              errors: []
            }
          ]);

          const hasErrors = form
            .getFieldsError()
            .some(({ errors }: any) => errors && errors.length > 0);

          setIsDisabled(hasErrors);
        }
      } catch (e) {
        setIsDisabled(true);
        form.setFields([
          {
            name: 'subdomain',
            errors: [
              'Domain can only include lowercase letters (a to z) and numbers.'
            ]
          }
        ]);
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  };

  return (
    <div className="step_container" style={{ maxWidth: 420, margin: '0 auto' }}>
      <p className="step_container__title">Create organization name</p>
      <p className="step_container__description">
        This is the home for the workplaces you will create later.
      </p>
      <div className="step_container__body">
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: 'Please enter organization name'
            }
          ]}
        >
          <Input
            placeholder="Add organization name"
            size="large"
            maxLength={61}
            onChange={(e) => {
              onChangeSearchTerm(e.target.value);
            }}
          />
        </Form.Item>

        {form.isFieldsTouched() && (
          <div className="subdomain_field" style={{ position: 'relative' }}>
            <Form.Item
              name="subdomain"
              className="organization_name_place"
              label="Create organization domain"
              rules={[
                {
                  required: true,
                  message: 'Please enter domain name'
                }
              ]}
            >
              <Popover
                title="Domain can only include:"
                placement={windowWidth > 990 ? 'right' : 'top'}
                content={<ul>{subdomainPopoverContent}</ul>}
                overlayClassName="custom_popover"
                trigger="focus"
              >
                <Input
                  style={{
                    textTransform: 'lowercase',
                    width,
                    minWidth: '100%',
                    maxWidth: 420
                  }}
                  size="large"
                  value={subdomain || ''}
                  id="INPUT"
                  maxLength={61}
                  onChange={(e) => {
                    onChangeSubdomain(e.target.value);
                  }}
                  suffix=".app.streamwork.com"
                />
              </Popover>
              <span
                style={{
                  position: 'absolute',
                  left: 12,
                  fontSize: 16,
                  fontWeight: 600,
                  top: 10,
                  pointerEvents: 'none'
                }}
              >
                <span ref={span} style={{ color: 'transparent' }}>
                  {subdomain}
                </span>
              </span>
            </Form.Item>
          </div>
        )}
      </div>
    </div>
  );
}

export default OrganizationCreationStep;
