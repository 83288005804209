import { OrganizationItemDto } from '@api/Api';
import { hashedColor } from '@helpers/hashedColor';
import React from 'react';

function OrganizationAvatar({
  organization
}: {
  organization: OrganizationItemDto;
}) {
  if (organization.owner.picture.url)
    return <img src={organization.owner.picture.url} alt="" />;

  return (
    <div
      className="non_image_block"
      style={{
        backgroundColor: hashedColor(organization.id, 'organizationAvatar')
      }}
    >
      <div>{organization.name[0]}</div>
    </div>
  );
}

export default OrganizationAvatar;
