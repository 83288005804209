import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Button, Divider, Dropdown, Menu, message, Modal } from 'antd';
import { ReactComponent as CrossSvg } from '@assets/icons/cross.svg';
import { ReactComponent as ArrowDownSvg } from '@assets/icons/arrow-down.svg';
import { ReactComponent as SuccessSvg } from '@assets/icons/success.svg';
import { ReactComponent as EnterpriseSvg } from '@assets/icons/billing-enterprise-icon.svg';
import { useTypedSelector } from '@hooks';
import { useDispatch } from 'react-redux';
import {
  toggleBillingModal,
  toggleChangePlanDetailsModal,
  toggleReachedEntityLimitModal
} from '@redux/actions/modalAction';
import { useOrganization } from '@components/OrganizationBoundary';
import {
  GetSubscriptionSuccessDTO,
  UpdateSubscriptionSuccessDTO
} from '@api/Api';
import {
  useCancelOrganizationSubscription,
  useUpdateOrganizationSubscription
} from '@hooks/organization';
import { fetchOrganizationById } from '@redux/actions/organizations';
import { nonNull } from '@helpers/non-null';

const plansList = [
  {
    type: 'free' as const,
    title: 'Free',
    description: 'up to 2 users, 2GB storage',
    maxUsers: 2,
    minUsers: 0,
    storage: 2,
    price: {
      monthly: 0,
      annually: 0
    }
  },
  {
    type: 'starter' as const,
    title: 'Starter',
    description: 'up to 5 users, 200GB storage',
    maxUsers: 5,
    minUsers: 0,
    storage: 200,
    price: {
      monthly: 25,
      annually: 276
    }
  },
  {
    type: 'team' as const,
    title: 'Team',
    description: '5 - 25 users, 500GB storage',
    maxUsers: 25,
    minUsers: 5,
    storage: 500,
    price: {
      monthly: 35,
      annually: 384
    }
  },
  {
    type: 'enterprise' as const,
    title: 'Enterprise',
    description: '10 users minimum, custom storage',
    maxUsers: -1,
    minUsers: 10,
    storage: -1,
    price: {
      monthly: Number.POSITIVE_INFINITY,
      annually: Number.POSITIVE_INFINITY
    }
  }
];

function ChangePlanDetailsModal() {
  const { currentOrganization } = useOrganization();
  const [loading, setLoading] = useState<boolean>(false);
  const updateOrganizationSubscription = useUpdateOrganizationSubscription();
  const cancelOrganizationSubscription = useCancelOrganizationSubscription();
  const [plan, setPlan] = useState<'free' | 'starter' | 'team' | 'enterprise'>(
    'free'
  );
  const [method, setMethod] = useState<'monthly' | 'annually'>('monthly');

  const dispatch = useDispatch();
  const modalState = useTypedSelector(
    ({ modal }) => modal.changePlanDetailsModal
  );

  useEffect(() => {
    if (!modalState?.visible) return;
    setPlan(modalState.value);
    setMethod(modalState.paymentMethod);
  }, [modalState]);

  const onUpdateSubscription = async () => {
    if (!currentOrganization.entity) return;
    const organizationId = currentOrganization.entity.id;
    try {
      setLoading(true);
      let result: GetSubscriptionSuccessDTO | UpdateSubscriptionSuccessDTO;
      if (plan === 'free') {
        const { payload } = await cancelOrganizationSubscription({
          organizationId
        });
        result = payload as GetSubscriptionSuccessDTO;
      } else {
        const { payload } = await updateOrganizationSubscription({
          organizationId,
          period: method,
          type: plan as 'starter' | 'team'
        });
        result = payload as UpdateSubscriptionSuccessDTO;
      }
      if ('paymentUrl' in result && result.paymentUrl) {
        window.open(result.paymentUrl, '_self');
        return;
      }
      dispatch(fetchOrganizationById({ organizationId, fetchType: 'refresh' }));
      message.success({
        key: 'change-plan-modal',
        content: `Welcome to the StreamWork ${result.subscription.name} plan!`,
        icon: <SuccessSvg className="anticon" />,
        className: 'message-dark-modal',
        duration: 3
      });
    } catch (err) {
      const errorCode = (err as any).response?.data?.errorCode;
      let entity = 'unknown';
      if (errorCode === 'assets_size_limit_exceeded') entity = 'assets size';
      if (errorCode === 'subscription_users_limit_error') entity = 'user';

      dispatch(
        toggleReachedEntityLimitModal({
          visible: true,
          entity: entity as any,
          hideUpgradeButton: true
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const planItem = useMemo(
    () => nonNull(plansList.find((item) => item.type === plan)),
    [plan]
  );

  const chargableSeats = Math.max(
    currentOrganization.seatsCount.count,
    planItem.minUsers
  );

  const totalCharge = useCallback(
    () => planItem.price[method] * chargableSeats,
    [method, planItem, chargableSeats]
  )();

  const planType = currentOrganization.entity?.planType ?? 'free';
  const planStatus = currentOrganization.entity?.planStatus ?? 'active';
  const planPeriod = currentOrganization.entity?.planPeriod ?? 'monthly';
  const planIsCanceled =
    currentOrganization.entity?.planWillBeCanceled ?? false;

  const isButtonDisabled = useMemo(() => {
    if (plan === 'enterprise') return true;
    if (planType !== plan || planPeriod !== method) {
      return false;
    }
    if (planStatus === 'trialing' || planIsCanceled) {
      return false;
    }
    return true;
  }, [planType, planStatus, planPeriod, planIsCanceled, method, plan]);

  const buttonCopy = useMemo(() => {
    if (planType !== plan || planPeriod !== method) {
      return plan === 'free' ? 'Downgrade' : 'Update';
    }
    if (planStatus === 'trialing' || planIsCanceled) {
      return 'Update';
    }
    return 'Current';
  }, [planType, planStatus, planPeriod, planIsCanceled, method, plan]);

  const usageStats = useMemo(() => {
    const result: { label: string; usage: string }[] = [];
    if (planItem.maxUsers !== -1) {
      result.push({
        label: 'seats',
        usage: `${currentOrganization.seatsCount.count}/${planItem.maxUsers}`
      });
    }
    const storageInGB =
      currentOrganization.assetsSummarySize.size / 1024 / 1024 / 1024;
    result.push({
      label: 'storage',
      usage: `${storageInGB.toFixed(2)} GB / ${planItem.storage} GB`
    });
    return result;
  }, [currentOrganization, planItem]);

  return (
    <Modal
      visible={modalState?.visible}
      footer={null}
      destroyOnClose
      centered={true}
      closeIcon={<CrossSvg />}
      width={744}
      onCancel={() => dispatch(toggleChangePlanDetailsModal(null))}
      zIndex={1006}
    >
      <div className="modal_container">
        <h2
          className="modal_container__title"
          style={{ fontSize: 22, marginBottom: 32, justifyContent: 'center' }}
        >
          Update your StreamWork plan
        </h2>
        <div style={{ display: 'flex' }}>
          <div className="update_plan_actions">
            <div style={{ paddingBottom: 8 }}>StreamWork Plan</div>
            <Dropdown
              className="select_plan"
              overlayClassName="select_plan_overlay"
              trigger={['click']}
              overlay={
                <Menu style={{ minWidth: 300 }} selectedKeys={[plan]}>
                  {plansList.map((item) => (
                    <Menu.Item
                      key={item.type}
                      onClick={() => setPlan(item.type)}
                    >
                      <div className="title">{item.title}</div>
                      <div className="description"> • {item.description}</div>
                    </Menu.Item>
                  ))}
                </Menu>
              }
            >
              <span>
                <span style={{ textTransform: 'capitalize' }}>{plan}</span>
                <ArrowDownSvg className="arrow" />
              </span>
            </Dropdown>
            <div className="subscription-title-container">
              <span>Subscription</span>
              <span>Annually -10%</span>
            </div>
            <Dropdown
              className="select_plan"
              trigger={['click']}
              overlay={
                <Menu style={{ minWidth: 300 }} selectedKeys={[method]}>
                  <Menu.Item
                    key="monthly"
                    onClick={({ key }) => setMethod(key as any)}
                  >
                    Monthly
                  </Menu.Item>
                  <Menu.Item
                    key="annually"
                    onClick={({ key }) => setMethod(key as any)}
                  >
                    Annually
                  </Menu.Item>
                </Menu>
              }
            >
              <span>
                <span style={{ textTransform: 'capitalize' }}>{method}</span>
                <ArrowDownSvg className="arrow" />
              </span>
            </Dropdown>
          </div>
          <div className="update_plan_information">
            {plan !== 'enterprise' && (
              <div className="stats">
                You are using{' '}
                {usageStats.map((item, index) => (
                  <Fragment key={index}>
                    <strong>{item.usage}</strong> {item.label}
                    {index < usageStats.length - 1 && ' and '}
                  </Fragment>
                ))}
              </div>
            )}
            {plan === 'enterprise' ? (
              <div className="charge_block charge_block_enterprise">
                <EnterpriseSvg />
                <div className="enterprise_copy">
                  Is your team larger than 10? Contact us about an enterprise
                  plan built to scale with your organization
                </div>
                <Button
                  size="small"
                  type="link"
                  onClick={() => {
                    window.open('https://www.streamwork.com/contact', '_blank');
                  }}
                >
                  Chat with our sales team
                </Button>
              </div>
            ) : (
              <div className="charge_block">
                <div>
                  <div className="charge">
                    <div className="amount">${totalCharge} </div>
                    <div className="period">
                      / {method === 'monthly' ? 'month' : 'year'}
                    </div>
                  </div>
                  <div className="info">
                    <div className="copy">Total charge</div>
                  </div>
                </div>
                <div>
                  <div className="charge_calculations">
                    <div className="line" />
                    <div>
                      <div>
                        <span>Number of seats:</span>
                        <span>{chargableSeats}</span>
                      </div>
                      <div>
                        <span>Cost per seat:</span>
                        <span>${totalCharge / chargableSeats}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <Divider className="modal_container__divider" />
        <div className="update_plan_story">
          By clicking &quot;Update&quot;, you agree that you will be charged the
          updated fees listed above on a pro-rated basis and your subscription
          will automatically renew based on the date of your original
          subscription until cancelled. You may cancel as described in our{' '}
          <a
            href="https://www.streamwork.com/terms-of-service#term-and-termination"
            target="_blank"
            rel="noreferrer"
          >
            Term and Termination
          </a>{' '}
          section in the StreamWork Terms of Service. You also agree to the
          StreamWork{' '}
          <a
            href="https://www.streamwork.com/terms-of-service"
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>{' '}
          and{' '}
          <a
            href="https://www.streamwork.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          .
        </div>
        <div className="modal_container__actions" style={{ marginTop: 32 }}>
          <Button
            type="ghost"
            size="large"
            style={{
              marginRight: 'auto',
              fontSize: 16,
              height: 48,
              padding: '8px 32px'
            }}
            onClick={() => {
              dispatch(toggleBillingModal({ visible: true }));
              dispatch(toggleChangePlanDetailsModal(null));
            }}
          >
            Compare plans
          </Button>
          <Button
            type="text"
            size="large"
            style={{
              marginRight: 16,
              border: 'none',
              height: 48,
              padding: '8px 32px'
            }}
            onClick={() => dispatch(toggleChangePlanDetailsModal(null))}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            style={{
              width: 154,
              height: 48
            }}
            loading={loading}
            disabled={isButtonDisabled}
            onClick={async () => {
              await onUpdateSubscription();
              dispatch(toggleChangePlanDetailsModal(null));
            }}
          >
            {buttonCopy}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ChangePlanDetailsModal;
