import React, { useEffect, useRef, useState } from 'react';
import {
  CreateOrganizationDto,
  InviteWorkspaceMembersMemberDto
} from '@api/Api';
import { createWorkspace, inviteWorkspaceMembers } from '@api/Workspace';

import { Carousel, Form, Button, Progress } from 'antd';
import { XsWidth, FromXsWidth } from '@components/Responsive';
import { ReactComponent as CircleArrowLeft } from '@assets/icons/arrow-left.svg';
import { ReactComponent as CircleArrowRight } from '@assets/icons/arrow-right.svg';
import { redirectToSubdomain } from '@components/OrganizationBoundary';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '@hooks/useAuth';
import LogoTabletDark from '@assets/images/logo_tablet_dark.png';
import { createOrganization } from '@api/Organization';
import { removeExtraSpaces } from '@helpers/stringTransformers';
import { useTypedSelector } from '@hooks';
import { ownOrganizationItemStateSelector } from '@redux/selectors/organizations';
import { MixpanelService, MixpanelEventType } from '@services/mixpanelService';
import { useMixpanel } from 'react-mixpanel-browser';
import moment from 'moment';
import InvitationStep from './InvitationStep';
import OrganizationCreationStep from './OrganizationCreationStep';

function OnboardingSteps() {
  const { user } = useAuth(true, 'internal');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const trialToken = searchParams.get('trial');
  const ownOrganization = useTypedSelector((state) =>
    ownOrganizationItemStateSelector(state)
  );
  const [isDisabled, setIsDisabled] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);

  const [form] = Form.useForm();
  const { logout } = useAuth();
  const [loading, setLoading] = useState(false);

  const carouselRef = useRef<any>(null);

  useEffect(() => {
    if (ownOrganization) navigate('../');
  }, [navigate, ownOrganization]);

  const carouselStepChanged = (from: number, to: number) => {
    setCurrentStep(to);
  };

  const mixpanel = useMixpanel();

  const createOrgAndWorkspace = async ({
    name,
    subdomain
  }: CreateOrganizationDto) => {
    const {
      data: { organization }
    } = await createOrganization({
      name: removeExtraSpaces(name),
      subdomain,
      paidSubscriptionType: 'starter',
      paidSubscriptionPeriod: 'monthly',
      trialToken
    });
    const {
      data: { workspace, demoCampaignId }
    } = await createWorkspace({
      organizationId: organization.id,
      name: removeExtraSpaces(name)
    });
    MixpanelService.track(
      mixpanel,
      user.id,
      MixpanelEventType.SIGN_UP_FOR_TRIAL,
      {
        email: user.email,
        name: user.name,
        registrationDate: new Date().toISOString(),
        registrationMethod:
          user.primaryProviderId === 'google-oauth2' ? 'google' : 'website',
        planType: organization.planType || 'free',
        emailVerified: user.emailVerified,
        trialEndDate: organization.trialEndDate,
        trialDays: organization.trialEndDate
          ? Math.abs(
              Math.round(
                moment(organization.trialEndDate).diff(new Date(), 'days', true)
              )
            )
          : 0
      }
    );
    return { organization, workspace, demoCampaignId };
  };

  const onFinish = async (payload: CreateOrganizationDto) => {
    try {
      setLoading(true);
      const { organization, demoCampaignId } = await createOrgAndWorkspace(
        payload
      );
      redirectToSubdomain(organization.subdomain, 'start', [
        { name: 'welcome', value: 'true' },
        { name: 'demoCampaignId', value: demoCampaignId }
      ]);
    } finally {
      setLoading(false);
    }
  };

  const onInviteMembers = async () => {
    const emails: InviteWorkspaceMembersMemberDto[] = form
      .getFieldValue('emails')
      .filter((el: string) => el && el.length > 1)
      .map((el: string) => ({
        memberIdOrEmail: el,
        role: 'workspace://member',
        customMessage: null
      }));
    const name = form.getFieldValue('name').trim();
    const subdomain = form.getFieldValue('subdomain').trim();

    try {
      setLoading(true);
      const { organization, workspace, demoCampaignId } =
        await createOrgAndWorkspace({
          name,
          subdomain
        });

      await inviteWorkspaceMembers(workspace.id, emails);

      redirectToSubdomain(organization.subdomain, 'start', [
        { name: 'welcome', value: 'true' },
        { name: 'invitation', value: 'true' },
        { name: 'demoCampaignId', value: demoCampaignId }
      ]);
    } finally {
      setLoading(false);
    }
  };

  const onFieldsChange = (_: any, all: any[]) => {
    const hasErrors = all.some(
      ({ errors }: any) => errors && errors.length > 0
    );
    setIsDisabled(hasErrors);
  };

  const onOnboradingClose = async () => {
    logout({ returnTo: window.location.origin });
  };

  return (
    <div className="onboarding_container">
      <XsWidth>
        <div className="onboarding_container__head">
          <Progress showInfo={false} percent={(100 / 3) * (currentStep + 1)} />
          <span className="onboarding_container__controller_info onboarding_container__controller_info__mobile">
            {currentStep + 1} / <span className="total">2</span>
          </span>
          <div className="onboarding_container__logotype">
            <img src={LogoTabletDark} alt="" />
          </div>
        </div>
      </XsWidth>
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
        requiredMark={false}
      >
        <Carousel
          ref={carouselRef}
          dots={false}
          effect="fade"
          swipe={!isDisabled}
          beforeChange={carouselStepChanged}
        >
          <div className="onboarding_container__step onboarding_container__step--creation">
            <OrganizationCreationStep
              form={form}
              setIsDisabled={setIsDisabled}
            />
          </div>
          <div className="onboarding_container__step">
            <InvitationStep
              form={form}
              loading={loading}
              onInviteMembers={onInviteMembers}
            />
          </div>
        </Carousel>
        <div className="onboarding_container__controller">
          <XsWidth>
            {currentStep === 0 ? (
              <Button
                className="onboarding_container__controller_button onboarding_container__controller_button--prev"
                type="text"
                onClick={() => onOnboradingClose()}
              >
                <div className="icon">
                  <CircleArrowLeft style={{ position: 'relative' }} />
                </div>
                Back to sign in
              </Button>
            ) : (
              <Button
                className="onboarding_container__controller_button onboarding_container__controller_button--prev"
                type="text"
                onClick={() => carouselRef.current.prev()}
              >
                <div className="icon">
                  <CircleArrowLeft style={{ position: 'relative' }} />
                </div>
                Back
              </Button>
            )}
          </XsWidth>
          <XsWidth>
            <Button
              loading={loading}
              className="onboarding_container__controller_button onboarding_container__controller_button--next"
              type="primary"
              htmlType={currentStep === 1 ? 'submit' : 'button'}
              onClick={() => currentStep !== 1 && carouselRef.current.next()}
              disabled={isDisabled}
            >
              {currentStep === 1 ? 'Skip' : 'Next'}
            </Button>
          </XsWidth>
          <FromXsWidth>
            <Button
              className="onboarding_container__controller_button onboarding_container__controller_button--prev"
              type="text"
              onClick={() => {
                if (currentStep !== 0) carouselRef.current.prev();
                else onOnboradingClose();
                if (document.activeElement instanceof HTMLElement) {
                  document.activeElement.blur();
                }
              }}
            >
              <>
                {' '}
                <div className="icon">
                  <CircleArrowLeft />
                </div>
                Back{' '}
              </>
            </Button>
          </FromXsWidth>
          <FromXsWidth>
            <span className="onboarding_container__controller_info">
              {currentStep + 1} / <span className="total">2</span>
            </span>
          </FromXsWidth>
          <FromXsWidth>
            <Button
              loading={loading}
              className="onboarding_container__controller_button onboarding_container__controller_button--next"
              type="text"
              htmlType={currentStep === 1 ? 'submit' : 'button'}
              onClick={() => {
                if (currentStep !== 1) carouselRef.current.next();
                if (document.activeElement instanceof HTMLElement) {
                  document.activeElement.blur();
                }
              }}
              disabled={isDisabled}
            >
              {currentStep === 1 ? 'Skip' : 'Next'}
              <div className="icon">
                <CircleArrowRight />
              </div>
            </Button>
          </FromXsWidth>
        </div>
      </Form>
    </div>
  );
}

export default OnboardingSteps;
