import OrganizationAvatar from '@components/OrganizationAvatar';
import { useOrganization } from '@components/OrganizationBoundary';
import { useTypedSelector } from '@hooks';
import useTypedDispatch from '@hooks/useTypedDispatch';
import {
  toggleWorkspaceOrganizationDeletionModal,
  toggleWorkspaceOrganizationLeaveModal
} from '@redux/actions/modalAction';
import { organizationItemStateSelector } from '@redux/selectors/organizations';
import { ReactComponent as ActionSvg } from '@assets/icons/action.svg';
import { Button, Dropdown, Menu } from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import React, { memo, useMemo } from 'react';

type OrganizationsDropdownMenuItemProps = {
  organizationId: string;
  setIsMenuVisible: (v: boolean) => void;
};

export default memo(function OrganizationsDropdownMenuItem({
  organizationId,
  setIsMenuVisible
}: OrganizationsDropdownMenuItemProps) {
  const dispatch = useTypedDispatch();
  const { currentOrganization } = useOrganization();
  const organization = useTypedSelector((state) =>
    organizationItemStateSelector(state, { organizationId })
  );
  const menuItems: ItemType[] = useMemo(() => {
    const items = [];
    if (!organization.entity?.owner.me)
      items.push({
        key: 'leave_organization',
        label: 'Leave organization',
        onClick: () => {
          if (!organization.entity) return;
          dispatch(
            toggleWorkspaceOrganizationLeaveModal({
              entity: organization.entity,
              visible: true,
              entityName: 'organization'
            })
          );
        }
      });
    if (organization.entity?.permissions.delete)
      items.push({
        key: 'delete_organization',
        label: 'Delete organization',
        onClick: () => {
          if (!organization.entity) return;
          dispatch(
            toggleWorkspaceOrganizationDeletionModal({
              entityName: 'organization',
              visible: true,
              entity: organization.entity
            })
          );
        }
      });
    return items;
  }, [dispatch, organization.entity]);

  if (!organization.entity) return null;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div className="workspace_item">
        <OrganizationAvatar organization={organization.entity} />
      </div>
      <p className="title">{organization.entity.name}</p>
      {organization.entity.id === currentOrganization.entity?.id && (
        <span className="active_organization" />
      )}
      <Dropdown
        className="select_dots"
        overlay={
          <Menu
            style={{ padding: '19px 12px' }}
            items={menuItems}
            onClick={(e) => {
              e.domEvent.stopPropagation();
              setIsMenuVisible(false);
            }}
          />
        }
        placement="bottom"
        trigger={['click']}
      >
        <Button
          onClick={(e) => e.stopPropagation()}
          type="text"
          size="small"
          style={{ padding: 0, width: 24, height: 24 }}
        >
          <ActionSvg style={{ marginRight: 0 }} />
        </Button>
      </Dropdown>
    </div>
  );
});
