import { useOrganization } from '@components/OrganizationBoundary';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as OrganizationSvg } from '@assets/icons/organization.svg';
import { Button } from 'antd';
import { useAuth } from '@hooks/useAuth';
import LeaveEntityModel from '@components/Modals/LeaveEntityModel';
import OrganizationsDropdownMenu from '@components/Organizations/OrganizationsDropdownMenu/OrganizationsDropdownMenu';
import useTypedDispatch from '@hooks/useTypedDispatch';
import {
  fetchOrganizationSeatsUsed,
  fetchOrganizationStorageUsed
} from '@redux/actions/organizations';
import { useFetch } from '@hooks/useFetch';
import { organizationItemStateSelector } from '@redux/selectors/organizations';
import { toggleBillingModal } from '@redux/actions/modalAction';
import {
  BillingModal,
  ChangePlanDetailsModal
} from '@components/Modals/BillingModal';

function OrganizationLocked() {
  const { logout } = useAuth(true, 'internal');
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { currentOrganization } = useOrganization(false);
  const organizationId = currentOrganization?.entity?.id;

  useEffect(() => {
    if (!currentOrganization?.entity?.lockDate) {
      navigate('/campaigns/all');
      return;
    }
    const nowDate = new Date().getTime();
    const lockDate = new Date(currentOrganization.entity.lockDate).getTime();
    if (nowDate < lockDate) navigate('/campaigns/all');
  }, [navigate, currentOrganization]);

  const onUpgrade = useCallback(async () => {
    if (!currentOrganization?.entity?.permissions.billing) return;

    dispatch(toggleBillingModal({ visible: true }));
  }, [currentOrganization, dispatch]);

  useFetch({
    disabled: !organizationId,
    key: `organizations-seats-used-${organizationId}`,
    selector: (state) =>
      organizationItemStateSelector(state, {
        organizationId: organizationId as string
      }).seatsCount.countFetch,
    fetch: (fetchType) =>
      dispatch(
        fetchOrganizationSeatsUsed({
          fetchType,
          organizationId: organizationId as string
        })
      )
  });
  useFetch({
    disabled: !organizationId,
    key: `organizations-storage-used-${organizationId}`,
    selector: (state) =>
      organizationItemStateSelector(state, {
        organizationId: organizationId as string
      }).assetsSummarySize.sizeFetch,
    fetch: (fetchType) =>
      dispatch(
        fetchOrganizationStorageUsed({
          fetchType,
          organizationId: organizationId as string
        })
      )
  });

  return (
    <div className="organization_locked_container">
      <div className="organization_locked_container__info">
        <h2 className="head">
          You&apos;ve exceeded your seat or storage limit for your account
        </h2>
        <p className="copy">
          {currentOrganization?.entity?.permissions.billing ? (
            <>
              {' '}
              <Button type="link" onClick={onUpgrade}>
                Please upgrade
              </Button>{' '}
              to continue collaborating.
            </>
          ) : (
            <>
              Please ask your admin to upgrade to a paid plan to continue
              collaborating.
            </>
          )}
        </p>

        <OrganizationsDropdownMenu>
          <Button type="ghost" className="select_organization_btn">
            <OrganizationSvg />
            Change organization
          </Button>
        </OrganizationsDropdownMenu>
      </div>
      <div className="organization_locked_container__help">
        <p className="copy">
          New to StreamWork? Create your{' '}
          <Button
            type="link"
            onClick={() => {
              logout({ returnTo: window.location.origin });
            }}
          >
            own account
          </Button>
          and start collaborating.
        </p>
      </div>
      <LeaveEntityModel />
      <BillingModal />
      <ChangePlanDetailsModal />
    </div>
  );
}

export default OrganizationLocked;
