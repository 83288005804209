declare global {
  interface Window {
    gtag?: any;
  }
}

const gtagEnabled = process.env.NODE_ENV === 'production' && window.gtag;
export class GtagService {
  public static trackUserRegistration({ name }: { name: string }) {
    if (!gtagEnabled) {
      console.log('GTAG TIME EVENT', {
        eventType: 'UserRegistration',
        error: this.gtagError()
      });
      return;
    }

    window.gtag('event', 'generate_lead', {
      event_params: {
        form_name: name,
        submission_time: new Date().toISOString(),
        success: true
      }
    });
  }

  private static gtagError() {
    if (process.env.NODE_ENV !== 'production') return 'Not a production';
    if (!window.gtag) return 'Gtag embed code not found';
  }
}
