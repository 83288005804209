import React from 'react';
import { Table } from 'antd';
import './PlanFeatureTable.less';

function PlanFeatureTable({ tableContent }: { tableContent: any }) {
  const columns = [
    {
      title: 'Features',
      dataIndex: 'features',
      width: 320,
      render: (features: string) => (
        <div style={{ fontWeight: 600 }}>{features}</div>
      )
    },
    {
      title: 'Free',
      dataIndex: 'free',
      width: 320,
      render: (v: any) => <div>{v}</div>
    },
    {
      title: 'Starter',
      dataIndex: 'starter',
      width: 320,
      render: (v: any) => <div>{v}</div>
    },
    {
      title: 'Team',
      dataIndex: 'team',
      width: 320,
      render: (v: any) => <div>{v}</div>
    },
    {
      title: 'Enterprise',
      dataIndex: 'enterprise',
      width: 320,
      render: (v: any) => <div>{v}</div>
    }
  ];

  return (
    <div className="plan-feature-wrapper-container">
      <Table
        pagination={false}
        columns={columns}
        dataSource={tableContent}
        showHeader={false}
      />
    </div>
  );
}

export default PlanFeatureTable;
